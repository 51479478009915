import RouteData from './route-data'
import { app, grabToken } from './utils/feathers';

const RouteMapper = {
  Home: { import: () => import('./pages/landing-page') },
  Transcribe: { import: () => import('./pages/yt-scribe') },
  PBCC: { import: () => import('./pages/pbcc') },
  PBCC_missions: { import: () => import('./pages/pbcc/missions') },
  PBCC_ministry_partners: { import: () => import('./pages/pbcc/ministry-partner') },
  PBCC_church_groups: { import: () => import('./pages/pbcc/church-groups') },
  PBCC_campus_map: { import: () => import('./pages/pbcc/campus-map') },
  PBCC_church_ministries: { import: () => import('./pages/pbcc/church-ministries') },

  PBCC_churchteams_donors: { import: () => import('./pages/tools/churchteams-donors') },
  PBCC_admin: { import: () => import('./pages/pbcc/admin/') },
  PBCC_admin_missions_partner: { authenticate: true, import: () => import('./pages/pbcc/admin/missions-partner') },
  PBCC_admin_missions_partner_form: { authenticate: true, import: () => import('./pages/pbcc/admin/forms/missions-partner') },
  PBCC_admin_missions_partner_updates: { authenticate: true, import: () => import('./pages/pbcc/admin/forms/missions-partner'), property: 'UpdatesForm' },
  PBCC_admin_ministry_partner: { authenticate: true, import: () => import('./pages/pbcc/admin/ministry-partner') },
  PBCC_admin_ministry_partner_form: { authenticate: true, import: () => import('./pages/pbcc/admin/forms/ministry-partner') },
  PBCC_admin_church_ministries: { authenticate: true, import: () => import('./pages/pbcc/admin/church-ministry') },
  PBCC_admin_church_ministries_form: { authenticate: true, import: () => import('./pages/pbcc/admin/forms/church-ministry') },
  PBCC_admin_church_groups: { authenticate: true, import: () => import('./pages/pbcc/admin/church-groups') },
  PBCC_admin_church_groups_form: { authenticate: true, import: () => import('./pages/pbcc/admin/forms/church-groups') },
  ImgResizer: { import: () => import('./pages/tools/img-resizer') },
}

const Routes = _.mapValues(RouteData, (routeName) => {
  const cmp = RouteMapper[routeName]
  if(cmp.import) {
    return {
      onmatch: async () => {
        if (!app.get('user')) await grabToken()

        if (cmp.authenticate && !app.get('user')) {
          return m.route.set('/')
        }

        return cmp.import().then(m => m[cmp.property || 'default'])
      }
    }
  } else {
    return { render: vnode => m(cmp) };
  }
});

const DefaultRoute = '/'
m.route.prefix = ""

export { Routes, DefaultRoute };