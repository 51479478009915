import _ from "lodash"
import queue from 'async/queue';

const $modal = $(`<div id="modal-popup" class="manual modal"></div>`)
$modal.appendTo('body')
const modal = $modal.get(0)
modal.style.zIndex = Number.MAX_SAFE_INTEGER

// use a queue
const q = queue((asyncThunk, done) => asyncThunk().then(done), 1);

const makeModal = (vnode, config = {}) => {
  const { title, mode, done, defaultText, clickout_cancel } = config
  let result = undefined
  const finish = e => {
    $(e.target).closest('.modal').removeClass('is-active')
    done(result)
  }
  return [
    m('.modal-background', {
      onclick: e => {
        if (!clickout_cancel) return
        if (mode === 'confirm') result = false
        if (mode === 'prompt') result = null
        finish(e)
      }
    }),
    m('.modal-content',
      m("article.message", { class: config.class },
        title && m(".message-header", title),
        m("form.message-body", {
          onsubmit: e => {
            e.preventDefault()
            if (mode === 'confirm') result = true
            finish(e)
          },
          onreset: e => {
            e.preventDefault()
            if (mode === 'confirm') result = false
            if (mode === 'prompt') result = null
            finish(e)
          }
        },
          vnode,
          mode === 'prompt' && m('input.input.my-4', {
            defaultValue: defaultText,
            oncreate: async vnode => {
              result = defaultText
              vnode.dom.select()
              vnode.dom.focus()
            },
            oninput: e => result = e.target.value
          }),
          m.n('nav.level', m('.level-right > .level-item.buttons',
            mode !== 'alert' && m('button.button.is-rounded', { type: 'reset', }, 'Cancel'),
            m('button.button.is-primary.is-rounded', { type: 'submit', }, 'Ok'),
          ))
        )
      ),
    )
  ]
}

export const alert = (vnode, config = {}) => q.push(() => new Promise(done => {
  $modal.addClass('is-active')
  m.render(modal, makeModal(vnode, { ...config, mode: 'alert', done }))
}))

export const confirm = (vnode, config = {}) => q.push(() => new Promise(done => {
  $modal.addClass('is-active')
  m.render(modal, makeModal(vnode, { ...config, mode: 'confirm', done }))
}))

export const prompt = (vnode, defaultText, config = {}) => q.push(() => new Promise(done => {
  $modal.addClass('is-active')
  m.render(modal, makeModal(vnode, { ...config, defaultText, mode: 'prompt', done }))
}))