const site = process.env.SITE_ID || 'main'
// console.log('site', site)

const RouteData = {
  main: {
    '/': 'Home',
    '/transcribe': 'Transcribe',
    '/nepal-sermons': 'NepalSermons',
    '/nepal-sermons/new': 'NepalSermonsForm',
    '/nepal-sermons/:id': 'NepalSermonsView',
  },
  pbcc: {
    '/': 'PBCC',
    '/missions': 'PBCC_missions',
    '/ministry-partners': 'PBCC_ministry_partners',
    '/church-ministries': 'PBCC_church_ministries',
    '/church-groups': 'PBCC_church_groups',
    '/campus-map': 'PBCC_campus_map',
    '/admin/': 'PBCC_admin',
    '/admin/missions-partner': 'PBCC_admin_missions_partner',
    '/admin/forms/missions-partner': 'PBCC_admin_missions_partner_form',
    '/admin/forms/missions-partner/updates': 'PBCC_admin_missions_partner_updates',
    '/admin/ministry-partner': 'PBCC_admin_ministry_partner',
    '/admin/forms/ministry-partner': 'PBCC_admin_ministry_partner_form',
    '/admin/church-ministries': 'PBCC_admin_church_ministries',
    '/admin/forms/church-ministries': 'PBCC_admin_church_ministries_form',
    '/admin/church-groups': 'PBCC_admin_church_groups',
    '/admin/forms/church-groups': 'PBCC_admin_church_groups_form',
    '/tools/churchteams-donors': 'PBCC_churchteams_donors',
    '/tools/img-resizer': 'ImgResizer'
  }
};

module.exports = RouteData[site]